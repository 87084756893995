import { format } from 'date-fns';
import useSWR from 'swr';
import { Doctor } from 'types/doctor';
import { post, patch, httpDelete } from './http';
import { useInfiniteScroll } from './useInfiniteScroll';

type PrescriptionData = {
  results: Array<Prescription>;
  count: number;
  next?: string | null;
  previous?: string | null;
};

export type Prescription = {
  id: number;
  patient: number;
  doctor: Doctor;
  serial_number: string;
  date: string;
  title: string;
  pharmaceutical_free_text: string;
  amount: number;
  unit: string;
  prescription_number: string;
  appointment: number;
  reason_to_change_preparation: string;
  procedure: string;
  indica_sativa: string;
  share_of_thc: number;
  share_of_cbd: number;
  pzn_number: string;
  external_id?: string;
  pharmaceutical_type: string;
  form: string;
  intake: string;
  single_dosis: number;
  max_daily_dosis: number;
  duration: string;
  prescription_text: string;
  producer: string;
  [index: string]: any;
};

export function usePrescriptions(patient: number, appointmentId: number) {
  const paginationData = useInfiniteScroll<PrescriptionData>({
    url: `medical/prescriptions/?patient_id=${patient}&appointment_id=${appointmentId}`,
    pageSize: 99,
  });

  return paginationData;
}

export function usePrescription(id: undefined | null | string) {
  const { data, error, mutate } = useSWR<Prescription>(`medical/prescriptions/${id}/`);

  return { data, error, mutate };
}

export type Unit = 'gram' | 'milliliter' | 'piece';

export function createPrescription({
  patient,
  appointmentId,
  date = new Date(),
  amount,
  unit,
}: {
  patient: number;
  appointmentId: number;
  date?: Date;
  amount?: number;
  unit?: Unit;
}) {
  return post('medical/prescriptions/', {
    patient,
    appointment: appointmentId,
    date: format(date, 'yyyy-MM-dd'),
    amount,
    unit,
  });
}

export function updatePrescription(prescription: { id: number } & Partial<Prescription>) {
  return patch(`medical/prescriptions/${prescription.id}/`, prescription);
}

export const deletePrescription = (id: number) => {
  return httpDelete(`medical/prescriptions/${id}/`);
};
