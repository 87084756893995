import { useDocuments } from 'data/useDocuments';
import { PatientSurvey } from 'data/usePatients';
import { ReactComponent as BracketIcon } from 'icons/bracket.svg';

type SurveyResultProps = {
  survey: PatientSurvey;
  patientId: number;
};

const TITLES: Record<string, string> = {
  symptoms: 'Symptome',
  symptoms_description: 'Beschreibung der Symptome',
  symptoms_diagnosis_document: 'Diagnose',
  symptom_severity: 'Schwere der Symptome',
  previous_treatment: 'Vorherige Behandlung',
  previous_treatment_details: 'Details zur vorherigen Behandlung',
  previous_treatment_document: 'Dokument zur vorherigen Behandlung',
  cannabis_treatment: 'Cannabis Behandlung',
  cannabis_treatment_details: 'Details zur Cannabis Behandlung',
  cannabis_treatment_document: 'Dokument zur Cannabis Behandlung',
  existing_conditions: 'Vorhandene Krankheiten',
  additional_notes: 'Zusätzliche Notizen',
  pregnant_or_breastfeeding: 'Sind Sie schwanger oder stillen Sie?',
  lack_of_existing_conditions_statement:
    'Hiermit bestätige ich an keiner der genannten Erkrankungen zu leiden',
};

export default function SurveyResult({ survey, patientId }: SurveyResultProps) {
  const { data } = useDocuments(patientId);

  function getValue(value: any) {
    if (typeof value === 'boolean') {
      return value ? 'Ja' : 'Nein';
    } else if (typeof value === 'number' || typeof value === 'string') {
      return value;
    } else if (Array.isArray(value)) {
      return value.join(', ');
    } else if (typeof value === 'object' && value !== null) {
      return value.name;
    }
  }

  function getDocumentUrl(value: any) {
    return data?.find((doc: any) => doc.id === value?.id)?.asset;
  }

  return (
    <ul>
      {Object.entries(survey).map(([key, value]) => (
        <>
          {!!TITLES[key] && value !== undefined && value !== null && value !== '' && (
            <li className="mb-3 flex flex-col" key={key}>
              <strong className="text-swopa-primary-dark-blue mb-1">{TITLES[key]}</strong>
              <span className="relative text-swopa-secondary-light-blue font-semibold pl-7">
                <BracketIcon className="absolute left-3 top-1" />
                {!!getDocumentUrl(value) ? (
                  <a
                    href={getDocumentUrl(value)}
                    target="_blank"
                    rel="noreferrer"
                    className="underline"
                  >
                    {getValue(value)}
                  </a>
                ) : (
                  <p>{getValue(value)}</p>
                )}
              </span>
            </li>
          )}
        </>
      ))}
    </ul>
  );
}
