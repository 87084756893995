import useSWR from 'swr';
import { useInfiniteScroll } from './useInfiniteScroll';

type PharmaceuticalData = {
  results: Array<Pharmaceutical>;
  count: number;
  next?: string | null;
  previous?: string | null;
};

export type Pharmaceutical = {
  id: number;
  name: string;
  title: string;
  producer: string;
  pharmaceutical_id: string;
  external_id?: string;
  pzn_number?: string;
};

export function usePharmaceutical(id: undefined | null | string) {
  const { data, error, mutate } = useSWR<Pharmaceutical>(`medical/pharmaceuticals/${id}/`);

  return { data, error, mutate };
}

export function usePharmaceuticals(pharmacyId: number | null) {
  const pharmaceuticalsEndpoint = pharmacyId
    ? `medical/pharmacies/${pharmacyId}/pharmaceutical/`
    : 'medical/pharmaceuticals/';
  const paginationData = useInfiniteScroll<PharmaceuticalData>({
    url: pharmaceuticalsEndpoint,
    pageSize: 500,
  });

  return { ...paginationData };
}
