import { ReactNode, useEffect, useMemo, useState } from 'react';
import PlainInput, { PlainInputProps } from 'components/inputs/PlainInput';
import Select, { SelectProps } from 'components/inputs/Select';
import SuffixInput, { SuffixInputProps } from 'components/SuffixInput';
import TextArea, { TextAreaProps } from 'components/inputs/TextArea';
import { ReactComponent as Info } from 'icons/info3.svg';
import ReactSelect from 'react-select';
import {
  deletePrescription,
  Prescription,
  updatePrescription,
  usePrescriptions,
} from 'data/usePrescriptions';
import { format } from 'date-fns';
import { Formik } from 'formik';
import { useFieldAutosave } from 'hooks/useAutoSave';
import SectionWrapper from './SectionWrapper';
import { number, object } from 'yup';
import DeleteMenu from './DeleteMenu';
import SectionFooter from './SectionFooter';
import { useAppointmentContext } from 'contexts/Appointment';
import { Pharmaceutical, usePharmaceuticals } from 'data/usePharmaceuticals';
import PrescriptionDocumentDeliveryForm from '../forms/PrescriptionDocumentDeliveryForm';
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters';

let schema = object({
  amount: number().nullable(),
  share_of_thc: number().nullable().max(100, 'Anteil kann nicht über 100% liegen.'),
  share_of_cbd: number().nullable().max(100, 'Anteil kann nicht über 100% liegen.'),
});

export default function PrescriptionSection({
  item,
  expanded,
  appointmentId,
  pharmacyId,
  count,
  total,
}: {
  item: Prescription;
  expanded: boolean;
  appointmentId: number;
  count: number;
  total: number;
  pharmacyId: number | null;
}) {
  const { mutate } = usePrescriptions(item.patient, appointmentId);
  const { results: pharmaceuticals } = usePharmaceuticals(pharmacyId);
  const handleRefetchData = async () => await mutate();
  const { state, refreshState } = useAppointmentContext() as any;

  const doctorFirstName = item?.doctor?.first_name || '';
  const doctorLastName = item?.doctor?.last_name || '';
  const [selectedPresecription, setSelectedPresecription] = useState();
  const [thcCbdUnit, setThcCbdUnit] = useState('%');
  const [onetimeDosageUnit, setOnetimeDosageUnit] = useState('g');
  const [dailyDosageUnit, setDailyDosageUnit] = useState('g/Tag');
  const [type, setType] = useState(item.pharmaceutical_type);
  const [selectedClone, setSelectedClone] = useState(false);
  const [selectionDone, setSelectionDone] = useState(false);
  const [overwriteList, setOverwriteList] = useState<string[]>([]);
  const [avoidOverwriteList, setAvoidOverwriteList] = useState<string[]>([]);

  const pharmaceuticalsOptions = pharmaceuticals.map((item) => ({
    ...item,
    label: item.title,
    value: item.id,
  }));

  pharmaceuticalsOptions.push({
    label: 'Andere',
    value: 9999,
    id: 9999,
    name: 'Andere',
    title: 'Andere',
    producer: '',
    pharmaceutical_id: 'andere',
  });

  const neverOverride = [
    'id',
    'appointment',
    'doctor',
    'patient',
    'date',
    'created_at',
    'sortOrder',
    'type',
  ];

  const getAvoidOverwriteList = (values: any) => {
    let avoidOverwriteList = Object.keys(values).filter(
      (key) => values[key] && values[key] !== '' && neverOverride.indexOf(key) === -1,
    );
    setAvoidOverwriteList(avoidOverwriteList);
    return avoidOverwriteList;
  };

  const overwriteFields = (avoidOverwrite: string[], selected: any) => {
    const toUpdate: any = {};

    if (avoidOverwrite.indexOf('title') === -1) {
      toUpdate['title'] = selected.title;
    }

    if (avoidOverwrite.indexOf('indica_sativa') === -1) {
      toUpdate['indica_sativa'] = selected.genetics;
    }

    if (avoidOverwrite.indexOf('form') === -1) {
      toUpdate['form'] = selected.form;
    }

    if (avoidOverwrite.indexOf('amount') === -1) {
      toUpdate['amount'] = selected.amount;
    }
    if (avoidOverwrite.indexOf('unit') === -1) {
      toUpdate['unit'] = selected.amount_unit;
      changeUnit(selected.amount_unit);
    }

    if (avoidOverwrite.indexOf('intake') === -1) {
      toUpdate['intake'] = selected.intake;
    }

    if (avoidOverwrite.indexOf('share_of_thc') === -1) {
      toUpdate['share_of_thc'] = selected.proportion_thc;
    }

    if (avoidOverwrite.indexOf('share_of_cbd') === -1) {
      toUpdate['share_of_cbd'] = selected.proportion_cbd;
    }

    if (avoidOverwrite.indexOf('single_dosis') === -1) {
      toUpdate['single_dosis'] = selected.single_dosis || '';
    }

    if (avoidOverwrite.indexOf('duration') === -1) {
      toUpdate['duration'] = selected.duration;
    }

    if (avoidOverwrite.indexOf('max_daily_dosis') === -1) {
      toUpdate['max_daily_dosis'] = selected.max_daily_dosis;
    }

    if (avoidOverwrite.indexOf('pharmaceutical_type') === -1) {
      toUpdate['pharmaceutical_type'] = selected.pharmaceutical_type;
    }

    if (avoidOverwrite.indexOf('producer') === -1) {
      toUpdate['producer'] = selected.producer;
    }

    if (avoidOverwrite.indexOf('pzn_number') === -1) {
      toUpdate['pzn_number'] = selected.pzn_number;
    }

    return toUpdate;
  };

  const updateFormValues = (setValues: any, selected: any, values: any) => {
    if (selected) {
      const toUpdate = overwriteFields(getAvoidOverwriteList(values), selected);
      setOverwriteList(Object.keys(toUpdate));
      setValues({ ...values, ...toUpdate }, false);
      setType(selected.pharmaceutical_type);
      setSelectedClone(selected);
      setSelectionDone(true);
    } else {
      setSelectionDone(false);
    }
  };

  const changeUnit = (value: string) => {
    if (value === 'gram') {
      setOnetimeDosageUnit('g');
      setDailyDosageUnit('g/Tag');
      setThcCbdUnit('%');
    }
    if (value === 'milliliter') {
      setOnetimeDosageUnit('ml');
      setDailyDosageUnit('ml/Tag');
      setThcCbdUnit('mg/ml');
    }
    if (value === 'piece') {
      setOnetimeDosageUnit('Stück');
      setDailyDosageUnit('Stück/Tag');
      setThcCbdUnit('mg/Stück');
    }
  };

  const onUnitChange = (ev: any, handleChange: any) => {
    const value = ev.target.value;
    changeUnit(value);
    handleChange(ev);
  };

  const onTypeChange = (ev: any, handleChange: any) => {
    const value = ev.target.value;
    setType(value);
    handleChange(ev);
  };

  const overwrite = (setValues: any, values: any) => {
    if (selectedClone) {
      const toUpdate = overwriteFields([], selectedClone);
      setOverwriteList(Object.keys(toUpdate));
      setValues({ ...values, ...toUpdate }, false);
      setAvoidOverwriteList([]);
      setSelectionDone(true);
    }
  };

  const onMagicFill = (field: string, setFieldValue: any, values: any) => {
    let extra = '';
    const unitMap: any = {
      gram: 'g',
      milliliter: 'ml',
      piece: 'Stück',
    };

    if (values.single_dosis) {
      extra = `Einzeldosis: ${values.single_dosis} ${unitMap[values.unit]}; `;
    }

    let moreDetails = '';

    if (values.pharmaceutical_type === 'Cannabisextrakt') {
      moreDetails += ` (enthält ${values.share_of_thc} ${thcCbdUnit} THC und ${values.share_of_cbd}`;
      moreDetails += ` ${thcCbdUnit} CBD; insg. ${values.amount * values.share_of_thc} ${
        thcCbdUnit.split('/')[0]
      } THC);`;
    }

    if (values.pharmaceutical_type === 'Cannabisblüte') {
      moreDetails += ` ${values.form};`;
    }

    let result = `${extra}max. ${values.max_daily_dosis} ${unitMap[values.unit]}/Tag ${
      values.duration
    } ${values.intake}`;

    if (
      field === 'procedure' &&
      values.max_daily_dosis &&
      unitMap[values.unit] &&
      values.duration
    ) {
      setFieldValue(field, result);
    }

    if (
      field === 'prescription_text' &&
      values.pharmaceutical_type &&
      values.title &&
      values.amount &&
      unitMap[values.unit]
    ) {
      let text = `${values.pharmaceutical_type} ${values.title}, ${values.amount} ${
        unitMap[values.unit]
      },`;

      text += `${moreDetails} Dosierung: ${result}`;
      setFieldValue(field, text);
    }
  };

  useEffect(() => {
    changeUnit(item.unit);
  }, []);

  return (
    <SectionWrapper
      handleRefetchData={handleRefetchData}
      expanded={expanded}
      headline={total > 1 ? `Verschreibung Nr. ${count + 1}` : `Verschreibung`}
      content={
        <>
          <Formik
            initialValues={item}
            validationSchema={schema}
            onSubmit={() => {}}
            enableReinitialize
          >
            {({ values, errors, handleChange, setValues, setFieldValue }) => (
              <form className="flex flex-col relative">
                {!selectedPresecription && !values.title && (
                  <div className="absolute top-20 bg-white bg-opacity-80 left-0 right-0 bottom-[-70px] z-50"></div>
                )}
                <div className="mb-4">
                  <ReactSelect
                    styles={{
                      menu: (baseStyles, state) => ({
                        ...baseStyles,
                        zIndex: 999,
                      }),
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor: state.isFocused
                          ? '#4091F7'
                          : !selectedPresecription && !item.title
                            ? '#E9082A'
                            : '#CDCDCD',
                        boxShadow: 'none',
                      }),
                    }}
                    className="basic-single"
                    isClearable={true}
                    isSearchable={true}
                    name="prescription_search"
                    placeholder="Suche"
                    value={pharmaceuticalsOptions.find(
                      (option) => option.value === values.prescription_search,
                    )}
                    filterOption={(
                      candidate: FilterOptionOption<Pharmaceutical>,
                      input: string,
                    ) => {
                      return (
                        candidate.label.toLowerCase().includes(input.toLowerCase()) ||
                        candidate.data.producer.toLowerCase().includes(input.toLowerCase())
                      );
                    }}
                    options={pharmaceuticalsOptions}
                    onChange={(option: any) => {
                      if (option && option.value) {
                        updateFormValues(setValues, option, values);
                        updatePrescription({ id: values.id, external_id: option.external_id });
                        setFieldValue('prescription_search', option.value);
                        setSelectedPresecription(option);
                      } else {
                        setFieldValue('prescription_search', '');
                      }
                    }}
                  />
                  {!selectedPresecription && !item.title && (
                    <div className="text-swopa-warning-red">Bitte Arzneimittel auswählen</div>
                  )}
                  {pharmacyId && (
                    <div className="rounded-md text-sm text-amber-800 p-2 mt-4 flex border border-solid border-amber-500 bg-amber-400 border-r-2">
                      Es werden nur vorrätige Produkte angezeigt. Nicht verfügbare Medikamente
                      können nicht ausgewählt werden.
                    </div>
                  )}
                </div>
                {avoidOverwriteList.length > 0 && (
                  <div className="mb-4 flex">
                    <span className="text-swopa-secondary-light-blue mr-1 flex items-center">
                      <Info className="mr-1" /> Bereits ausgefüllte Felder wurden nicht befüllt.
                    </span>
                    <span
                      onClick={() => overwrite(setValues, values)}
                      className="underline cursor-pointer font-semibold text-swopa-grey-4"
                    >
                      Überschreiben
                    </span>
                  </div>
                )}
                <div className="border-b border-solid border-swopa-grey-2 mb-4 mx-[-1rem] "></div>
                <div className="grid grid-cols-2 gap-4">
                  <AutoSaveInput
                    className="mb-4"
                    name="title"
                    label="Präparat"
                    type="text"
                    error={errors.title}
                    onChange={handleChange}
                    value={values.title}
                    isPublic={true}
                    overwriteList={overwriteList}
                    avoidOverwriteList={avoidOverwriteList}
                    isFullWidth={true}
                  />
                  <AutoSaveInput
                    className="mb-4"
                    name="producer"
                    label="Hersteller"
                    type="text"
                    error={errors.producer}
                    onChange={handleChange}
                    value={values.producer}
                    overwriteList={overwriteList}
                    avoidOverwriteList={avoidOverwriteList}
                    isFullWidth={true}
                  />
                </div>
                <div className="mb-4 grid grid-cols-4 gap-4">
                  <AutoSaveInput
                    name="pzn_number"
                    label="PZN Nummer"
                    type="text"
                    error={errors.pzn_number}
                    onChange={handleChange}
                    value={values.pzn_number}
                    overwriteList={overwriteList}
                    avoidOverwriteList={avoidOverwriteList}
                    isFullWidth={true}
                    required={true}
                  />
                </div>
                <AutoSaveTextArea
                  className="mb-4"
                  disabled={values.title !== 'Andere'}
                  name="pharmaceutical_free_text"
                  id="pharmaceutical_free_text"
                  label="Präparat Freitext"
                  placeholder="Hier eintippen ..."
                  value={values.pharmaceutical_free_text}
                  onChange={handleChange}
                />
                <div className="grid grid-cols-3 gap-4">
                  <AutoSaveSelect
                    className="mb-4"
                    name="pharmaceutical_type"
                    label="Typ"
                    options={[
                      { value: '', label: '' },
                      { value: 'Cannabisblüte', label: 'Cannabisblüte' },
                      { value: 'Cannabisextrakt', label: 'Cannabisextrakt' },
                      { value: 'Cannaxan 701-1.2', label: 'Cannaxan 701-1.2' },
                      { value: 'Dronabinol', label: 'Dronabinol' },
                      { value: 'Ölige CBD-Lösung', label: 'Ölige CBD-Lösung' },
                    ]}
                    value={values.pharmaceutical_type}
                    onChange={(ev) => onTypeChange(ev, handleChange)}
                    overwriteList={overwriteList}
                    avoidOverwriteList={avoidOverwriteList}
                    isFullWidth={true}
                  />
                  <AutoSaveSelect
                    className="mb-4"
                    name="indica_sativa"
                    label="Indica oder Sativa"
                    options={[
                      { value: '', label: '' },
                      { value: 'hybrid', label: 'Hybrid' },
                      { value: 'indica', label: 'Indica' },
                      { value: 'sativa', label: 'Sativa' },
                      { value: 'hybrid indica-dominant', label: 'Hybrid Indica-dominant' },
                      { value: 'hybrid sativa-dominant', label: 'Hybrid Sativa-dominant' },
                    ]}
                    value={values.indica_sativa}
                    onChange={handleChange}
                    isPublic={true}
                    overwriteList={overwriteList}
                    avoidOverwriteList={avoidOverwriteList}
                    isFullWidth={true}
                  />
                  {type === 'Cannabisblüte' && (
                    <AutoSaveSelect
                      className="mb-4"
                      name="form"
                      label="Abgabeform"
                      options={[
                        { value: '', label: '' },
                        { value: 'unzerkleinert', label: 'unzerkleinert' },
                        { value: 'NRF 22.12 (zerkleinert)', label: 'NRF 22.12 (zerkleinert)' },
                      ]}
                      value={values.form}
                      onChange={handleChange}
                      overwriteList={overwriteList}
                      avoidOverwriteList={avoidOverwriteList}
                      isFullWidth={true}
                    />
                  )}
                </div>
                <div className="grid grid-cols-3 gap-4">
                  <AutoSaveInput
                    className="mb-4"
                    name="amount"
                    label="Menge"
                    type="number"
                    error={errors.amount}
                    onChange={handleChange}
                    value={values.amount}
                    isPublic={true}
                    overwriteList={overwriteList}
                    avoidOverwriteList={avoidOverwriteList}
                    isFullWidth={true}
                  />
                  <AutoSaveSelect
                    className="mb-4"
                    name="unit"
                    label="Einheit"
                    options={[
                      { value: '', label: '' },
                      { value: 'gram', label: 'Gramm' },
                      { value: 'milliliter', label: 'Milliliter' },
                      { value: 'piece', label: 'Stück' },
                    ]}
                    value={values.unit}
                    onChange={(ev) => onUnitChange(ev, handleChange)}
                    overwriteList={overwriteList}
                    avoidOverwriteList={avoidOverwriteList}
                    isFullWidth={true}
                  />
                  <AutoSaveInput
                    className="mb-4"
                    name="intake"
                    label="Einnahme"
                    type="text"
                    error={errors.intake}
                    onChange={handleChange}
                    value={values.intake}
                    overwriteList={overwriteList}
                    avoidOverwriteList={avoidOverwriteList}
                    isFullWidth={true}
                  />
                </div>
                <div className="grid grid-cols-3 gap-4">
                  <AutoSaveSuffixInput
                    className="mb-4"
                    name="share_of_thc"
                    label="Anteil THC"
                    suffix={thcCbdUnit}
                    type="number"
                    value={values.share_of_thc}
                    onChange={handleChange}
                    error={errors.share_of_thc}
                    isPublic={true}
                    overwriteList={overwriteList}
                    avoidOverwriteList={avoidOverwriteList}
                    isFullWidth={true}
                  />
                  <AutoSaveSuffixInput
                    className="mb-4"
                    name="share_of_cbd"
                    label="Anteil CBD"
                    suffix={thcCbdUnit}
                    type="number"
                    value={values.share_of_cbd}
                    onChange={handleChange}
                    error={errors.share_of_cbd}
                    isPublic={true}
                    overwriteList={overwriteList}
                    avoidOverwriteList={avoidOverwriteList}
                    isFullWidth={true}
                  />
                </div>
                <div className="grid grid-cols-3 gap-4">
                  <AutoSaveSuffixInput
                    className="mb-4"
                    name="single_dosis"
                    label="Einzeldosis"
                    suffix={onetimeDosageUnit}
                    type="number"
                    value={values.single_dosis}
                    onChange={handleChange}
                    error={errors.single_dosis}
                    overwriteList={overwriteList}
                    avoidOverwriteList={avoidOverwriteList}
                    isFullWidth={true}
                  />
                  <AutoSaveSuffixInput
                    className="mb-4"
                    name="max_daily_dosis"
                    label="Max. Tagesdosis"
                    suffix={dailyDosageUnit}
                    type="number"
                    value={values.max_daily_dosis}
                    onChange={handleChange}
                    error={errors.max_daily_dosis}
                    overwriteList={overwriteList}
                    avoidOverwriteList={avoidOverwriteList}
                    isFullWidth={true}
                  />
                  <AutoSaveInput
                    className="mb-4"
                    name="duration"
                    label="Dauer"
                    type="text"
                    error={errors.duration}
                    onChange={handleChange}
                    value={values.duration}
                    overwriteList={overwriteList}
                    avoidOverwriteList={avoidOverwriteList}
                    isFullWidth={true}
                  />
                </div>
                <AutoSaveTextArea
                  className="mb-4"
                  name="procedure"
                  id="procedure"
                  label="Empfohlene Dosierung"
                  placeholder="Hier eintippen ..."
                  value={values.procedure}
                  onChange={handleChange}
                  isPublic={true}
                  isMagic={true}
                  onMagicFill={() => onMagicFill('procedure', setFieldValue, values)}
                />
                <AutoSaveTextArea
                  className="mb-4"
                  name="prescription_text"
                  id="prescription_text"
                  label="Rezept Text"
                  placeholder="Hier eintippen ..."
                  value={values.prescription_text}
                  onChange={handleChange}
                  isMagic={true}
                  onMagicFill={() => onMagicFill('prescription_text', setFieldValue, values)}
                />
                <AutoSaveTextArea
                  className="mb-4"
                  name="reason_to_change_preparation"
                  id="reason_to_change_preparation"
                  label="Ggf. Grund für Präparatsänderung"
                  placeholder="Hier eintippen ..."
                  value={values.reason_to_change_preparation}
                  onChange={handleChange}
                />
              </form>
            )}
          </Formik>
          <PrescriptionDocumentDeliveryForm
            prescription={item}
            handleRefetchData={handleRefetchData}
          />
        </>
      }
      footer={
        <SectionFooter
          left={
            <div className="flex items-center">
              <span className="text-xs block mr-4">
                {format(new Date(item.date), 'dd.MM.yyyy')}
              </span>
              <div className="mt-1">
                <DeleteMenu
                  onDelete={async () => {
                    await deletePrescription(item.id);
                    await mutate();
                    refreshState();
                  }}
                />
              </div>
            </div>
          }
          right={
            <span className="text-xs">
              {doctorFirstName} {doctorLastName}
            </span>
          }
        />
      }
    ></SectionWrapper>
  );
}

function AutoSaveTextArea(props: TextAreaProps & { name: string }) {
  const { loading, finished } = useFieldAutosave({ update: updatePrescription, name: props.name });

  return <TextArea finished={finished} {...props} loading={loading} />;
}

function AutoSaveSuffixInput(props: SuffixInputProps & { name: string }) {
  const { loading, finished } = useFieldAutosave({ update: updatePrescription, name: props.name });
  return <SuffixInput finished={finished} {...props} loading={loading} />;
}

function AutoSaveInput(props: PlainInputProps & { name: string }) {
  const { loading, finished } = useFieldAutosave({ update: updatePrescription, name: props.name });
  return <PlainInput finished={finished} {...props} loading={loading} />;
}

function AutoSaveSelect<Options extends { value: string | number; label: string }>(
  props: SelectProps<Options> & { name: string },
) {
  const { loading, finished } = useFieldAutosave({ update: updatePrescription, name: props.name });
  return <Select finished={finished} {...props} loading={loading} />;
}
